import { useEffect,useState } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
  useNavigate,
} from "react-router-dom";

import Content from "./pages/content/Content.js";
import Login from "./pages/content/content-main/login/login.js"
import { auth , firebase} from "./configs/firebase.config.js";
import "./App.css"

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const [splash, setSplash] = useState(true)
  const pathname = location.pathname;
  const navigate=useNavigate()

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/otpverification":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);
  const isActive=(path)=>location.pathname===path;

  useEffect(()=>{
    console.log(auth);
    
    auth.onAuthStateChanged(function (user) {
      if (user) {
        //do nothin
        setSplash(false)
      } else {
        navigate("/login");
        setSplash(false)
      }
    });
  },[splash])

  return (
   <div className="appresponsive">

     {splash && (
      <div className="SplashScreen">
            <img className="splash_img" alt="" src="/Splash-Screen.avif" />
      </div>)}

    <Routes >
      <Route path="/" element={<Login/>}/>
      <Route path="/login" element={<Login/>}/>
      <Route path="/content/txn" element={<Content/>}/>
      <Route path="/content/load" element={<Content/>}/>
      <Route path="/content/stmts" element={<Content/>}/>
      <Route path="/content/payout" element={<Content/>}/>
    </Routes>
   </div>
  );
} 
export default App;
