export default process.env.NODE_ENV != "production"?{
    node_api_endpoint: 'https://sandboxportalapi.eazypayouts.com/v1',
    firebaseConfig : {
        apiKey: "AIzaSyDOem_OSJJlrWk1xdxZJwT262Xuf20SoLI",
        authDomain: "phedora-payouts.firebaseapp.com",
        projectId: "phedora-payouts",
        storageBucket: "phedora-payouts.appspot.com",
        messagingSenderId: "342415860715",
        appId: "1:342415860715:web:64c1d9d725f4ace5512fae",
        measurementId: "G-87QEYBXN8Y"
      },
  }:{
      node_api_endpoint: 'https://portalapi.eazypayouts.com/v1',
      firebaseConfig :  {
        apiKey: "AIzaSyDOem_OSJJlrWk1xdxZJwT262Xuf20SoLI",
        authDomain: "phedora-payouts.firebaseapp.com",
        projectId: "phedora-payouts",
        storageBucket: "phedora-payouts.appspot.com",
        messagingSenderId: "342415860715",
        appId: "1:342415860715:web:64c1d9d725f4ace5512fae",
        measurementId: "G-87QEYBXN8Y"
      },
  }