import "./SideNaveContainer.css"
import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { auth } from "../../configs/firebase.config";

const SideNaveContainer = ({className="",closenav} ) => {

const location=useLocation();
const isActive=(path)=>location.pathname===path;//function to find the current active file
  
  const navigate = useNavigate();

  let hidden_btn=[""]//list of hidden buttons Classname
  const ishidden =(name)=> hidden_btn.includes(name);//function to hide the button

  let disable_btn=[""]//list of disabled buttons Classname

  const onStatementsButtonsContainerClick = useCallback(() => {
    if(!disable_btn.includes("statements-buttons2")){
    navigate("/content/stmts");
    closenav()
    }
  }, [navigate]);

  const onpayoutButtonsContainerClick = useCallback(() => {
    if(!disable_btn.includes("payout-buttons2")){
    navigate("/content/payout");
    closenav()
    }
  }, [navigate]);

  const onTransactionsButtonsContainerClick = useCallback(() => {
    if(!disable_btn.includes("transactions-buttons2")) {
      navigate("/content/txn");
      closenav()
    }
  }, [navigate]);

  const onLoadsButtonsContainerClick=useCallback(()=>{
    if(!disable_btn.includes("loads-buttons2")) {
      navigate("/content/load");
      closenav()
    }
  }, [navigate])
  const handleLogout = useCallback(async () => {
    await auth.signOut();
  }, [navigate]);

  return (
    <div className="contain-sidenav">
    <div className={`sidenavecontainer2 , ${className}`}>
      <div className="logo2">
        <img
          className="eazypayouts-2-1-icon2"
          loading="lazy"
          alt=""
          src="/eazypayouts2-1.svg"
        />
        <img className="group-icon2" loading="lazy" alt="" src="/group.svg" />
        
      </div>
      <div className="sidebar-content1">
        <div className="actions">
          <div
          id="load-btn"
            className={`loads-buttons2 ${ ishidden("loads-buttons2") ? "hidden-div" : "" } ${disable_btn.includes("loads-buttons2") ? "disabled" : ""}`}
            onClick={onLoadsButtonsContainerClick}
           style={{
            backgroundColor: isActive('/content/load') ? 'coral':"" ,
            color: isActive('/content/load') ? 'white':"" 
            }}>
            <img
              className="loads-buttons-item"
              loading="lazy"
              alt=""
              src={isActive('/content/load') ? "/boxarrowindown_white.svg" : "/boxarrowindown.svg"}
            />
            <div className="loads1">Loads</div>
          </div>

          <div
          id="statement-btn"
            className={`statements-buttons2 ${ ishidden("statements-buttons2") ? "hidden-div" : "" } ${disable_btn.includes("statements-buttons2") ? "disabled" : ""}`}
            onClick={onStatementsButtonsContainerClick}
            style={{
              backgroundColor: isActive('/content/stmts') ? 'coral':"",
              color: isActive('/content/stmts') ? 'white':""  
            }}
          >
            <img
              className="statements-buttons-item"
              loading="lazy"
              alt=""
              src={isActive('/content/stmts') ? "/group-2352_white.svg" : "/group-2352.svg"}
              
            />
            <div className="statements1">Statements</div>
          </div>
          <div
          id="transaction-btn"
            className={`transactions-buttons2 ${ ishidden("transactions-buttons2") ? "hidden-div" : "" } ${disable_btn.includes("transactions-buttons2") ? "disabled" : ""}`}
            onClick={onTransactionsButtonsContainerClick}
            style={{
              backgroundColor: isActive('/content/txn') ? 'coral':"",
              color: isActive('/content/txn') ? 'white':"" 
            }}
            
          >
            <img
              className="arrow-down-up-icon1"
              loading="lazy"
              alt=""
              src={isActive('/content/txn') ? "/arrowdownup_white.svg" : "/arrowdownup.svg"}
              
            />
            <div className="transactions1">Transactions</div>
          </div>

          <div
          id="statement-btn"
            className={`transactions-buttons2 ${ ishidden("transactions-buttons2") ? "hidden-div" : "" } ${disable_btn.includes("statements-buttons2") ? "disabled" : ""}`}
            onClick={onpayoutButtonsContainerClick}
            style={{
              backgroundColor: isActive('/content/payout') ? 'coral':"",
              color: isActive('/content/payout') ? 'white':""  
            }}
          >
            <img
              className="statements-buttons-item"
              loading="lazy"
              alt=""
              src={isActive('/content/payout') ? "/atm_active.svg" : "/atm1.svg"}
              
            />
            <div className="payoutStatus1">Payout status</div>
          </div>
        </div>

        <div className="logout">
        <div className="logout-buttons2" onClick={handleLogout}>
          <img
            className="box-arrow-right-icon2"
            loading="lazy"
            alt=""
            src="/boxarrowright.svg"
          />
          <div className="logout2">Logout</div>
        </div>
      </div>
      </div>
    </div>
    </div>
  );
};

SideNaveContainer.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
};

export default SideNaveContainer;
