import React, { useCallback, useState, useEffect } from "react";
import {Routes, Route, useNavigate, useLocation } from "react-router-dom";
import "../content/Content.css"
import { auth } from "../../configs/firebase.config";
import apiService from "../../services/api.service";
import TopNav from "./topnav/TopNav";

const URL = "https://bvd2etrns3.execute-api.ap-south-1.amazonaws.com/Prod/balance";
const URL1 = "https://bvd2etrns3.execute-api.ap-south-1.amazonaws.com/Prod/load";
const URL2 = "https://bvd2etrns3.execute-api.ap-south-1.amazonaws.com/Prod/company";

import EasypayoutStmtsScreen from "./content-main/stmts/stmts.js";
import EasypayoutTransactionScreen from "./content-main/transaction/transaction.js";
import SideNaveContainer from "../side-nav/SideNaveContainer.js";
import EasypayoutsLoadScreen from "./content-main/load/load.js";
import EazyPayOutPayoutScreen from "./content-main/payout/Payout.js"
const Content = () => {
  const location = useLocation()

const navigate = useNavigate();
const Active=(path)=>location.pathname===path;
  
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedAccount, setSelectedAccount] = useState("");
  const [accountOptions, setAccountOptions] = useState({});
  const [userProfile, setUserProfile] = useState({});
  const [splash, setSplash] = useState(true)

  auth.onAuthStateChanged(  function (user) {
  
    if (user) {
      //do nothin
    } else {
      window.recaptchaVerifier = null;
      localStorage.removeItem("user_profile");
      localStorage.removeItem("selectedCompany");
      localStorage.removeItem("selectedAccount");
      navigate("/login");
    }
  });
  
  useEffect( () => {
    const fetchData = async () => {
      try {
        var user_profile = localStorage.getItem("user_profile");
        console.log("user_profile : %o", JSON.stringify(user_profile));
        if (!user_profile) {
          var response = await apiService({ method: "post", path: "/user/get_user_profile", payload: {} });
          var user_profile = response.data;
          localStorage.setItem("user_profile", JSON.stringify(user_profile));
          setUserProfile(localStorage.getItem("user_profile"));
        } else {
          user_profile = JSON.parse(user_profile);
        }
        var company = Object.keys(user_profile.companyMap).sort();
        setAccountOptions(user_profile.companyMap);
        setSelectedCompany(company[0]);
        setSelectedAccount(user_profile.companyMap[company[0]].accounts[0])
        
        var srcSelectedCompany = localStorage.getItem("selectedCompany");
        if (srcSelectedCompany) {
          setSelectedCompany(srcSelectedCompany);
          var srcSelectedAcount = localStorage.getItem("selectedAccount");
          if (srcSelectedAcount) {
            setSelectedAccount(srcSelectedAcount);
          }
        }
      } catch (error) {
        localStorage.removeItem("user_profile");
        console.log("%o", error);
      }
    };

    try {
      fetchData();
    } catch (error) {
      console.log("%o", error);
    }
  }, []);

const updateselectedCompany = useCallback((company_inp)=>{setSelectedCompany(company_inp)})
const updateselectedAccount = useCallback((account_inp)=>{setSelectedAccount(account_inp)})
const Accountop=useCallback((list)=>{setAccountOptions(list)})
  return (
   < div className={`page-interactive-screen ${ !Active("/")&&!Active("/login") ? "inLogin" : "" }`}>     
     
        <div className="container_sidenave">
          <div className="sidenave">
          <SideNaveContainer closenav={()=>{console.log("noneed") }}/>
          </div>          
        </div>
        <div className="route-contai">

          <div className="topnav">
              <TopNav accountOptions={accountOptions} selectedCompany={selectedCompany} updateCompany={updateselectedCompany} selectedAccount={selectedAccount} updateAccount={updateselectedAccount} Accountop={Accountop}/>
          </div>
          
          <div className="routes">
            {Active("/content/load")&& (
              <EasypayoutsLoadScreen selectedCompany={selectedCompany} selectedAccount={selectedAccount} apiService={apiService}/>
            )}
            {Active("/content/stmts")&&(
              <EasypayoutStmtsScreen selectedCompany={selectedCompany} selectedAccount={selectedAccount} apiService={apiService}/>
            )}
            {Active("/content/txn") &&(
              <EasypayoutTransactionScreen selectedCompany={selectedCompany} selectedAccount={selectedAccount} apiService={apiService}/>
            )}{Active("/content/payout") &&(
              <EazyPayOutPayoutScreen selectedCompany={selectedCompany} selectedAccount={selectedAccount} apiService={apiService}/>
            )}
          </div>
        </div>
      </div>
  );
};

export default Content;
