import axios, { AxiosRequestHeaders } from "axios";
import { auth } from "../configs/firebase.config.js";
import config from "../configs/config";
const apiService = async ({ method, path,payload }) => {
  try {

    var authGaurd = new Promise((resolve,reject)=>{
      auth.onAuthStateChanged(function(user) {
        if (user != null) {
        resolve(user)
        } else {
        reject("not logged in")
        }
      });
    })
    
    await authGaurd;
    const user =   await auth.currentUser;
    const token = user ? await user.getIdToken() : null;
    var headers = {
      "Content-Type": "application/json",
    };
    if (user && token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    const result = await axios({
      method,
      url: `${config.node_api_endpoint}${path}`,
      data: payload,
      headers,
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};
export default apiService;