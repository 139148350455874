import MUIDataTable from "mui-datatables";
import { useState,useEffect } from "react";
import "../transaction/transaction.css"


const EasypayoutTransactionScreen = ({selectedCompany, selectedAccount, apiService}) => {

  let [accountTransactionData, setAccountTransactionData] =useState([]);
  const [userProfile, setUserProfile] = useState({});
  const [avlblc, setAvlblc] = useState("");
  const [acnumber,setAcnumber]=useState("");
  const [ifsc,setIfsc]=useState("");
  const [accountLoadDataColumns, setAccountLoadDataColumns] = useState([
    {
      name: "stmt_timestamp",
      label: "Date",
      options: {
        filter: true,
        sort: true,
      },
    },  {
      name: "ledger_event",
      label: "Event",
      options: {
        filter: true,
        sort: true,
      },
    }, {
      name: "contact_name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "debit_amount",
      label: "Debit",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          if(value==""){
            return "-"
          }
          return `₹ ${value}`;
          
        },
        setCellProps: (value) => ({ style: { color:"Red" } }),
      },
    },    {
      name: "credit_amount",
      label: "Credit",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          if(value==""){
            return "-";
          }
          return `₹ ${value}`;
        },
        setCellProps: (value) => ({ style: { color:"Green" } }),
       },
    },
    {
      name: "closing_balance",
      label: "A/C Balance",
      opptions: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "utr",
      label: "UTR/RRN",
      options: {
        filter: true,
        sort: true,
      
      },
    },
    {
      name: "beneficiary_acno",
      label: "A/c No / UPI",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "ref",
      label: "ref",
      options: {
        filter: true,
        sort: false,
        setCellProps: (value) => ({ style: {paddingRight:"110px"} }),
      },
    },
  ]);
  var mui_table_options = {
    rowsPerPage: 10,
    selectableRows: 'none',
    downloadOptions: { filename: "Account Transaction", separator: "," }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!selectedCompany || !selectedAccount) {
        setAvlblc("");
        setAcnumber("");
        setIfsc("");
        setAccountTransactionData([]);
        return;
      }
      setAvlblc("Loading ...");
      setIfsc("Loading ...")
      setAcnumber("Loading ...")
      try {
        var user_profile = localStorage.getItem("user_profile");
        if (!user_profile) {
          var response = await apiService({ method: "post", path: "/user/get_user_profile", payload: {} });
          var user_profile = response.data;
          localStorage.setItem("user_profile", JSON.stringify(user_profile));
          setUserProfile(localStorage.getItem("user_profile"));
        } else {
          user_profile = JSON.parse(user_profile);
        }
        var response = await apiService({ method: "post", path: "/user/get_balance_and_latest_transactions", payload: { company: selectedCompany, account: selectedAccount } });
        setAvlblc(response.data.balance);
        setAccountTransactionData(response.data.stmts);
        console.log(response.data.stmts);
        setAcnumber(response.data.van.acc_no);
        setIfsc(response.data.van.ifsc);
      } catch (error) {
        localStorage.removeItem("user_profile");
        console.log("%o", error);
      }
    };

    fetchData();
  }, [selectedCompany, selectedAccount]);
  
  return (
    <div className="Transaction-easypayouts-load-screen">
    <div className="Transaction-frame-parent4">
      
      <div className="Transaction-card-container">
        <div className="Transaction-frame-parent5">
          <div className="Transaction-frame-parent6">
            <div className="Transaction-vector-container">
              <img className="Transaction-vector-icon1" alt="" src="/vector.svg" />
            </div>
            <div className="Transaction-avaliable-balance-group">
              <b className="Transaction-loads12">Available Balance</b>
              <div className="Transaction-div20">₹ {avlblc}</div>
            </div>
          </div>
          <div className="Transaction-cash-stack1" />
        </div>
        <div className="Transaction-frame-parent5">
          <div className="Transaction-frame-parent6">
            <div className="Transaction-vector-container">
              <img className="Transaction-vector-icon2" alt="" src="/icon1.svg" />
            </div>
            <div className="Transaction-avaliable-balance-group">
              <b className="Transaction-loads12">Account Details</b>
              <div className="Transaction-div16">
                <span className="Transaction-acifsc"> Ac.no :</span> {acnumber}
              </div>
              <div className="Transaction-div16">
                <span className="Transaction-acifsc"> IFSC :</span> {ifsc}
              </div>
            </div>
          </div>
          <div className="Transaction-cash-stack1" />
        </div>
      </div>
      <div className="Transaction-table-content">
        <MUIDataTable
          title={"Latest Account Transactions"}
          data={accountTransactionData}
          columns={accountLoadDataColumns}
          options={mui_table_options}
        />
      </div>
    </div>
  </div>  
  );
};

export default EasypayoutTransactionScreen;
