import SideNaveContainer from "../../side-nav/SideNaveContainer.js";
import PortalDrawer from "./PortalDrawer/PortalDrawer.js";
import PropTypes from "prop-types";
import "./TopNav.css";
import React, { useCallback, useState, useEffect } from "react";
import {
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  Autocomplete,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import "./TopNav.css"
const TopBar = ({ accountOptions, selectedCompany, updateCompany ,selectedAccount ,updateAccount, Accountop}) => {
  const [isSideNaveContainerOpen, setSideNaveContainerOpen] = useState(false);

  let Companyoptions=Object.keys(accountOptions).sort();

  const openSideNaveContainer = useCallback(() => {
    setSideNaveContainerOpen(true);
  }, []);

  const closeSideNaveContainer = useCallback(() => {
    setSideNaveContainerOpen(false);
  }, []); 
  function handleInputChange(event, value) {
    updateCompany(value)
  }
  return (
    <>
      <header className="topbar">
        <div className="logo-parent">
          <div className="container-1">
          <div className="logo6">
            <div className="align">
            <img
              className="eazypayouts-2-1-icon6"
              alt=""
              src="/eazypayouts2-1.svg"
            />
            <img className="group-icon6" alt="" src="/group.svg" />
          </div>
          </div>
          <div className="align-logo">
        <div className="menu-03" onClick={openSideNaveContainer}>
            <img className="icon4" alt="" src="/menu03.svg" />
          </div>
          </div>
        </div>
        </div>
        <div className="container-2">
        <div className="selectoutlined-group">
            
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                value={selectedCompany}
                options={Companyoptions}
                onChange={handleInputChange}
                sx={{width:"220px",marginRight:"32px" }}
                renderInput={(params) => <TextField {...params} sx={{borderRadius:12}} label="Company Name" />}
            />

            <FormControl
              className="selectoutlined2"
              variant="outlined"
              sx={{
                alignSelf:"center",
                width: "220px",
                height: "42px",
                m: 0,
                p: 0,
                "& .MuiInputBase-root": {
                  m: 0,
                  p: 0,
                  minHeight: "42px",
                  justifyContent: "center",
                  display: "inline-flex",
                },
                "& .MuiInputLabel-root": {
                  m: 0,
                  p: 0,
                  minHeight: "4px",
                  display: "inline-flex",
                },
                "& .MuiMenuItem-root": {
                  m: 0,
                  p: 0,
                  height: "42px",
                  display: "inline-flex",
                },
              }}
            >
              <InputLabel color="primary">Account Name</InputLabel>
              <Select
                color="primary"
                size="medium"
                label="Ledger Name"
                disableunderline="true"
                displayEmpty
                value={selectedAccount}
                onChange={(e) => {
                  updateAccount(e.target.value);
                  localStorage.setItem("selectedAccount", e.target.value);
                }}
                sx={{
                  borderRadius: "12px",
                }}
                disabled={!selectedCompany}

              >

                {selectedCompany &&
                  accountOptions[selectedCompany].accounts.map((accountOption) => (
                    <MenuItem key={accountOption} value={accountOption}>
                      {accountOption}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText />
            </FormControl>
          </div>
          </div>
      </header>
    {isSideNaveContainerOpen && (
    <PortalDrawer
      overlayColor="rgba(113, 113, 113, 0.3)"
      placement="Left"
      onOutsideClick={closeSideNaveContainer}>
      <SideNaveContainer onClose={closeSideNaveContainer} closenav={closeSideNaveContainer} />
    </PortalDrawer>
)}
    </>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  arrowDropDown: PropTypes.string,
  arrowDropDown1: PropTypes.string,
};

export default TopBar;

