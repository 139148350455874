import MUIDataTable from "mui-datatables";
import { useState, useEffect } from "react";
import "../load/load.css"

const EasypayoutsLoadScreen= ({selectedCompany, selectedAccount, apiService})=>{

  let [accountLoadData, setAccountLoadData] =useState([]);
  const [userProfile, setUserProfile] = useState({});
  const [avlblc, setAvlblc] = useState("");
  const [acnumber,setAcnumber]=useState("");
  const [ifsc,setIfsc]=useState("");
  const [accountLoadDataColumns, setAccountLoadDataColumns] = useState([
    {
      name: "credited_timestamp",
      label: "Date",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "ledger_event",
      label: "Mode",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "amount",
      label: "Credit".padStart(20),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return `₹ ${value}`;
        },
      },
    },
    {
      name: "balance",
      label: "A/C Balance",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return `₹ ${value}`;
        },
      },
    },
    {
      name: "utr",
      label: "UTR/RRN",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "beneficiary_acno",
      label: "A/c No / UPI",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "beneficiary_name",
      label: "A/c Name", 
      options: {
        filter: true,
        sort: false, 
        setCellProps: (value) => ({ style: {paddingRight:"50px"} }),
        
      },
    },
  ]);
  var mui_table_options = {
    rowsPerPage: 10,
    selectableRows: 'none',
    downloadOptions: { filename: "Account Loads", separator: "," }
    };
  
  useEffect(() => {
    const fetchData = async () => {
      if (!selectedCompany || !selectedAccount) {
        setAvlblc("");
        setAcnumber("");
        setIfsc("");
        setAccountLoadData([]);
        return;
      }
      setIfsc("Loading ...")
      setAcnumber("Loading ...")
      setAvlblc("Loading ...");
      try {
        var user_profile = localStorage.getItem("user_profile");
        if (!user_profile) {
          var response = await apiService({ method: "post", path: "/user/get_user_profile", payload: {} });
          var user_profile = response.data;
          localStorage.setItem("user_profile", JSON.stringify(user_profile));
          setUserProfile(localStorage.getItem("user_profile"));
        } else {
          user_profile = JSON.parse(user_profile);
        }
        var response = await apiService({ method: "post", path: "/user/get_balance_loads", payload: { company: selectedCompany, account: selectedAccount } });
        setAvlblc(response.data.balance);
        setAccountLoadData();
        console.log(response.data.van_stmts);
        setAccountLoadData(response.data.van_stmts);
        setAcnumber(response.data.van.acc_no);
        setIfsc(response.data.van.ifsc);
        
        
      } catch (error) {
        localStorage.removeItem("user_profile");
        console.log("%o", error);
      }
    };

    fetchData();
  }, [selectedCompany, selectedAccount]);
  return (
    <div className="Load-easypayouts-load-screen">
  <div className="Load-frame-parent4">
    
    <div className="Load-card-container">
      <div className="Load-frame-parent5">
        <div className="Load-frame-parent6">
          <div className="Load-vector-container">
            <img className="Load-vector-icon1" alt="" src="/vector.svg" />
          </div>
          <div className="Load-avaliable-balance-group">
            <b className="Load-loads12">Available Balance</b>
            <div className="Load-div20">₹ {avlblc}</div>
          </div>
        </div>
        <div className="Load-cash-stack1" />
      </div>

      <div className="Load-frame-parent5">
        <div className="Load-frame-parent6">
          <div className="Load-vector-container">
            <img className="Load-vector-icon1" alt="" src="/icon1.svg" />
          </div>
          <div className="Load-avaliable-balance-group">
            <b className="Load-loads12">Account Details</b>
            <div className="Load-div16"><span className="Load-acifsc"> Ac.no :</span> {acnumber}</div>
            <div className="Load-div16"><span className="Load-acifsc"> IFSC :</span> {ifsc}</div>
          </div>
        </div>
        <div className="Load-cash-stack1" />
      </div>
    </div>
<div className="table_Container">
    <MUIDataTable
      title={"Latest Account Loads"}
      data={accountLoadData}
      columns={accountLoadDataColumns}
      options={mui_table_options}
    />
    </div>
  </div>
</div>

    )
}
export default EasypayoutsLoadScreen













