import MUIDataTable from "mui-datatables";
import { useState,useEffect } from "react";
import "../stmts/stmts.css"

const EasypayoutStmtsScreen = ({selectedCompany, selectedAccount, apiService}) => {
  
  const [userProfile, setUserProfile] = useState({});
  const [accountStmtsData, setAccountStmtsData] = useState([]);
  const [avlblc, setAvlblc] = useState("");
  const [accountLoadDataColumns, setAccountLoadDataColumns] = useState([
    {
      name: "filename",
      label: "File",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
           <a href={tableMeta.rowData[1]}>{value}</a>
          );
        }
      },
    },
    // ,{
    //   name: "filename",
    //   label: "Date",
    //   options: {
    //     filter: true,
    //     sort: true,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //        let arr=value.split("-")
    //        const date=`${arr[2]}-${arr[1]}-${arr[0]}`
    //        return date
    //     }
    //   },
    // },
   
    // {
    //   name: "filename",
    //   label: "File",
    //   options: {
    //     filter: true,
    //     sort: true,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       let arr=value.split("-")
    //       return `${arr[4]}-${arr[5]}`
    //     }
    //   },
    // },
         {
      name: "url",
      label: "url",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },  
  ]);
  
  var mui_table_options = {
    selectableRows: "none",
  };

  console.log("accountStmtsData",accountStmtsData);
  console.log("accountLoadDataColumns",accountLoadDataColumns);
  
  useEffect(() => {
   
    const fetchData = async () => {
      if (!selectedCompany || !selectedAccount) {
        setAvlblc("");
        setAccountStmtsData([]);
        return;
      }
      setAvlblc("Loading ...");
      try {
        var user_profile = localStorage.getItem("user_profile");
        if (!user_profile) {
          var response = await apiService({ method: "post", path: "/user/get_user_profile", payload: {} });
          var user_profile = response.data;
          localStorage.setItem("user_profile", JSON.stringify(user_profile));
          setUserProfile(localStorage.getItem("user_profile"));
        } else {
          user_profile = JSON.parse(user_profile);
        }
        var response = await apiService({ method: "post", path: "/user/get_statement_file_links", payload: { company: selectedCompany} });
        console.log(response.data.files);
        setAccountStmtsData(response.data.files);
      } catch (error) {
        localStorage.removeItem("user_profile");
        console.log("%o", error);
      }
    };

    fetchData();
  }, [selectedCompany, selectedAccount]);
 
  return (
    <div className="easypayouts-load-screen">
        <div className="frame-parent4">
        <div className="table_Container">  
          <MUIDataTable title={"Account Statement"} data={accountStmtsData} columns={accountLoadDataColumns} options={mui_table_options} />
        
          </div>
          </div>
      </div>

  );
};

export default EasypayoutStmtsScreen;
