import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import config from "./config";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

firebase.initializeApp(config.firebaseConfig);
// firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);
// firebase.auth().settings.appVerificationDisabledForTesting = true;

var auth = firebase.auth();
var firestore = firebase.firestore();


export { auth, firestore, firebase };
