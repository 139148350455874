import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import "./login.css";

import { firebase } from "../../../../configs/firebase.config.js";
import { auth } from "../../../../configs/firebase.config.js";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
// import Card from '@mui/material/Card'
import { styled, useTheme } from "@mui/material/styles";
import MuiCard from "@mui/material/Card";
import MuiFormControlLabel from "@mui/material/FormControlLabel";
import axios from "axios";
// ** Layout Import
import apiService from "../../../../services/api.service.js";
const Card = styled(MuiCard)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: { width: 450 },
}));
import config from "../../../../configs/config.js";
const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
  "& .MuiFormControlLabel-label": {
    fontSize: "0.875rem",
    color: theme.palette.text.secondary,
  },
}));

const Login = () => {
  const navigate = useNavigate();
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [otpRequsted, setOtpRequested] = useState(false);
  const [confirmation, setConfirmation] = useState(null);
  const [otpError, setOtpError] = useState("");
  const [loginError,setloginError] = useState("");

  auth.onAuthStateChanged(function (user) {
    if (user) {
      navigate("/content/load");
    } else {
      
      //user not logged in, so do nothing
    }
  });
  const recaptcha = () => {
    if (!window.recaptchaVerifier)
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("sign-in-button", {
        size: "invisible",
        callback: (response) => {
          // console.log('************************',response);
          // handlelogin();
        },
      });
  };

  const handleotp = async (e) => {
    if (otp.length === 6) {
      try {
        const confirmationResult = confirmation;
        const res = await confirmationResult.confirm(otp);
        console.log("Response User: ", res?.user);
        // setFeedback('OTP verified successfully!');
        var user_id_token = await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true);
        console.log("User ID",user_id_token);
        navigate("/content/load");
      } catch (error) {
        console.error(error);
        // setFeedback('');
        setOtpError("Incorrect OTP. Please try again.");
      }
    }
  };

  const handlelogin = async (e) => {
    recaptcha();
    if (!/([\d]{10})/g.test(mobile)) return window.alert("Mobile number should only contain numerals of length 10");
    try {
      if (mobile.length === 10) {
        var mobile_with_91 = "+91" + mobile;
        const appVerifier = window.recaptchaVerifier;
        // const check = await apiService({
        //   method: "post",
        //   endpoint: "/user",
        // });
        const check = await axios({
          method:"post",
          url: `${config.node_api_endpoint}/user`,
          data:  {
            phoneNumber:mobile,
          },
          headers:{
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        });
        if (!check.data.data) {
          alert("user not found!");
        } else {
          const confirmation = await auth.signInWithPhoneNumber(mobile_with_91, appVerifier);
          setOtpRequested(true);
          setConfirmation(confirmation);
        }
      }

      // alert("otp sent");
    } catch (error) {
      console.log("%o",error);
    }
  };
  return (
    <div className="login-div">
    <div className="container-login">
    </div>



      {window.innerWidth>600 &&(
        <div className="input-box-div">
      <Box className="content-center" sx={{  flex: .3,marginTop:8 }}>
        <Card className="container-login-input-card" sx={{borderRadius:"20px"}}>
      <div className="content-input">
          <CardContent sx={{ p: (theme) => `${theme.spacing(9, 7, 6.5)} !important` }}>
            <Box sx={{ mb: 2, display: "flex", alignItems: "center", justifyContent: "center" }}>
              <img src="/eazypayouts2-1.svg" style={{ width: 80, height: 80 }} />
            </Box>
            <Box sx={{ mb: 5 }}>
              <Typography sx={{ mb: 1.5, textAlign: "center", fontSize: "18px", fontWeight: 600, letterSpacing: "0.18px" }}>
                Welcome to your Eazy Payouts
              </Typography>
              <Typography variant="body2" sx={{ textAlign: "center", fontSize: "14px" }}>
                Please sign-in to continue
              </Typography>
            </Box>
            <div id="sign-in-button"></div>
            <form className="input" noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
              {otpRequsted ? (
                <TextField
                  className="input-box"
                  autoFocus
                  fullWidth
                  onChange={(e) => setOtp(e.target.value?.trim())}
                  inputProps={{ maxLength: 6 }}
                  value={otp}
                  label="Enter OTP"
                  sx={{ 
                    mb: "55px", 
                    borderRadius:"12px",
                  }}
                />
              ) : (
                <TextField
                  className="input-box"
                  autoFocus
                  fullWidth
                  // maxLength={10}
                  inputProps={{ maxLength: 10 }}
                  onChange={(e) => setMobile(e.target.value?.trim())}
                  value={mobile}
                  label="Enter your mobile number"
                  sx={{ 
                    mb: 5, 
                    borderRadius:"12px",
                    }}
                />
              )}
              {otpRequsted ? (
                <Button
                  onClick={(e) => handleotp(e)}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#f47e35",
                    },
                    mb: 5,
                      backgroundColor:"#fe914d", 
                      borderRadius:"12px"
                  }}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Verify OTP & Login
                </Button>
              ) : (
                <Button
                  onClick={(e) => handlelogin(e)}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{
                    "&:hover": {
                      backgroundColor: "#f47e35",
                    },
                    mb: 4,
                      backgroundColor:"#fe914d", 
                      borderRadius:"12px"
                  }}
                >
                  Get OTP
                </Button>
              )}
            </form>
            <Typography sx={{ color: "red" }}>{otpError}</Typography>
          </CardContent>
           </div>
        </Card>
      </Box>
      </div>)}



      {window.innerWidth<=600 &&(
        
        <div className="input-box-div2">
        <Box className="content-center" sx={{  flex: .3, marginTop:35, padding:"0 16px 0 16px "}}>
          <Card className="container-login-input-card" sx={{borderRadius:"20px"}}>
        <div className="content-input">
            <CardContent sx={{ p: (theme) => `${theme.spacing(6, 4, 5.5)} !important` }}>
              <Box sx={{ mb: 2, display: "flex", alignItems: "center", justifyContent: "center" }}>
                <img src="/eazypayouts2-1.svg" style={{ width: 60, height: 60 }} />
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography sx={{ mb: 2, textAlign: "center", fontSize: "14px", fontWeight: 600, letterSpacing: "0.12px" }}>
                  Welcome to your Eazy Payouts
                </Typography>
                <Typography variant="body2" sx={{ textAlign: "center", fontSize: "12px" }}>
                  Please sign-in to continue
                </Typography>
              </Box>
              <div id="sign-in-button"></div>
              <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                {otpRequsted ? (
                  <TextField
                    autoFocus
                    fullWidth
                    onChange={(e) => setOtp(e.target.value?.trim())}
                    inputProps={{ maxLength: 6 }}
                    value={otp}
                    label="Enter OTP"
                    sx={{ mb: 3 }}
                  />
                ) : (
                  <TextField
                    autoFocus
                    fullWidth
                    maxLength={10}
                    inputProps={{ maxLength: 10 }}
                    onChange={(e) => setMobile(e.target.value?.trim())}
                    value={mobile}
                    label="Enter your mobile number"
                    sx={{ mb: 3 }}
                  />
                )}
                {otpRequsted ? (
                  <Button
                    onClick={(e) => handleotp(e)}
                    sx={{
                      "&:hover": {
                        backgroundColor: "#f47e35",
                      },
                      mb: 7,
                      backgroundColor:"#fe914d",
                      borderRadius:"12px"
                    }}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Verify OTP & Login
                  </Button>
                ) : (
                  <Button
                    onClick={(e) => handlelogin(e)}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    sx={{
                      "&:hover": {
                        backgroundColor: "#f47e35",
                      },
                      mb: 4,
                      backgroundColor:"#fe914d", 
                      borderRadius:"12px",
                    
                    }}
                  >
                    Get OTP
                  </Button>
                )}
              </form>
              <Typography sx={{ color: "red" }}>{otpError}</Typography>
            </CardContent>
             </div>
          </Card>
        </Box>
        </div>
      )}
    </div>
  );
};

export default Login;
