import MUIDataTable from "mui-datatables";
import { useState, useEffect } from "react";
import "./payout.css";
import { TextField } from "@mui/material";
const EasypayoutsStatusScreen = ({
  selectedCompany,
  selectedAccount,
  apiService,
}) => {
  let [accountLoadData, setAccountLoadData] = useState([]);
  const [userProfile, setUserProfile] = useState({});
  const [avlblc, setAvlblc] = useState("");
  const [acnumber, setAcnumber] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [btnClicked, setbtnClicked] = useState(false);
  const [payoutRef, setpayoutRef] = useState("");
  const [payoutData, setPayOutData] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      if (!selectedCompany || !selectedAccount) {
        setAvlblc("");
        setAcnumber("");
        setIfsc("");
        setAccountLoadData([]);
        return;
      }
      setIfsc("Loading ...");
      setAcnumber("Loading ...");
      setAvlblc("Loading ...");
      try {
        var user_profile = localStorage.getItem("user_profile");
        if (!user_profile) {
          var response = await apiService({
            method: "post",
            path: "/user/get_user_profile",
            payload: {},
          });
          var user_profile = response.data;
          localStorage.setItem("user_profile", JSON.stringify(user_profile));
          setUserProfile(localStorage.getItem("user_profile"));
        } else {
          user_profile = JSON.parse(user_profile);
        }
        var response = await apiService({
          method: "post",
          path: "/user/get_balance_loads",
          payload: { company: selectedCompany, account: selectedAccount },
        });
        setAvlblc(response.data.balance);
        console.log(response.data.van_stmts);
        setAcnumber(response.data.van.acc_no);
        setIfsc(response.data.van.ifsc);
      } catch (error) {
        localStorage.removeItem("user_profile");
        console.log("%o", error);
      }
    };

    fetchData();
  }, [selectedCompany, selectedAccount]);

  const getPayLoad = async () => {
    try {
      console.log(payoutRef);

      var response = await apiService({
        method: "post",
        path: "/user/get_payout_status",
        payload: {
          company: selectedCompany,
          payout_ref_arr: [payoutRef.replaceAll(" ", "")],
        },
      });
      console.log(response.data);

      setPayOutData(response.data);
      setpayoutRef("");
      setbtnClicked(true);
    } catch (error) {
      localStorage.removeItem("user_profile");
      console.log("%o", error);
    }
  };

  return (
    <div className="Load-easypayouts-load-screen">
      <div className="Load-frame-parent4">

        <div className="Load-card-container">
          <div className="Load-frame-parent5">
            <div className="Load-frame-parent6">
              <div className="Load-vector-container">
                <img className="Load-vector-icon1" alt="" src="/vector.svg" />
              </div>
              <div className="Load-avaliable-balance-group">
                <b className="Load-loads12">Available Balance</b>
                <div className="Load-div20">₹ {avlblc}</div>
              </div>
            </div>
            <div className="Load-cash-stack1" />
          </div>

          <div className="Load-frame-parent5">
            <div className="Load-frame-parent6">
              <div className="Load-vector-container">
                <img className="Load-vector-icon1" alt="" src="/icon1.svg" />
              </div>
              <div className="Load-avaliable-balance-group">
                <b className="Load-loads12">Account Details</b>
                <div className="Load-div16">
                  <span className="Load-acifsc"> Ac.no :</span> {acnumber}
                </div>
                <div className="Load-div16">
                  <span className="Load-acifsc"> IFSC :</span> {ifsc}
                </div>
              </div>
            </div>
            <div className="Load-cash-stack1" />
          </div>
        </div>

        <div className="searchForPayoutStatus">
          <div className="getPayoutStatus">Get payout status</div>
          <div className="mobileMenuParent">
            <div className="mobileMenu">
              <TextField
                className="field"
                color="primary"
                label="Payout ref"
                variant="outlined"
                value={payoutRef}
                onChange={(e) => {
                  setpayoutRef(e.target.value);
                }}
                placeholder="Enter The Payout Ref"
                sx={{
                  borderRadius: "22px",
                  "& .MuiInputBase-root": { height: "100%" },
                  width: "100%",
                }}
              />
              <img className="trailingIcon2" alt="" />
            </div>
            <button
              className="apply"
              onClick={() => {
                // setpayoutRef(payoutRef.replaceAll(" ",""));
                console.log(`this${payoutRef.replaceAll(" ", "")}`);

                getPayLoad();
              }}
            >
              <img className="plusCircleIcon" alt="" src="/pluscircle.svg" />
              <b className="apply1">Search</b>
            </button>
          </div>
        </div>
        {btnClicked && payoutData && (
          <div className={"searchForPayoutStatus"}>
            <div className={"payoutStatus"}>Payout status</div>
            <div className={"searchForPayoutStatusInner"}>
              <div className={"frameParent"}>
                <div className={"frameGroup"}>
                  <div className={"payoutRefParent"}>
                    <div className={"payoutRef"}>Payout Ref</div>
                    <div className={"payoutRef"}>:</div>
                  </div>
                  <div className={"payoutRef"}>
                    {payoutData["0"].payout_ref || "Loading..."}
                  </div>
                </div>
                <div className={"frameGroup"}>
                  <div className={"payoutRefParent1"}>
                    <div className={"payoutRef"}>UTR</div>
                    <div className={"payoutRef"}>:</div>
                  </div>
               {payoutData["0"]?.status !== "Invalid PayoutRef"  ? <div className={"payoutRef"}>
                    {payoutData["0"]?.utr || "Loading..."}
                  </div>:  <div className={"payoutRef"}>
                    {payoutData["0"]?.utr || "NA"}
                  </div>}
                </div>
                <div className={"frameGroup"}>
                  <div className={"payoutRefParent2"}>
                    <div className={"payoutRef"}>Status</div>
                    <div className={"payoutRef"}>:</div>
                  </div>
                  <div
                    className={`${
                      payoutData["0"]?.status === "processed"
                        ? "payoutRefProcessed"
                        : payoutData["0"]?.status === "pending"
                        ? "payoutRefPending"
                        : "payoutRefFailed"
                    }`}
                  >
                    {payoutData["0"]?.status.toUpperCase() || "Loading..."}
                  </div>
                </div>
                <div className={"frameGroup"}>
                  <div className={"payoutRefParent3"}>
                    <div className={"payoutRef"}>Bank response</div>
                    <div className={"payoutRef"}>:</div>
                  </div>
                  {payoutData["0"].status == "failed" ? (
                    <div className={"payoutRef"}>
                      {(payoutData["0"].bank_response &&
                        JSON.parse(payoutData["0"]?.bank_response)
                          .statusDescription) ||
                        "NA"}
                    </div>
                  ) : payoutData["0"].status == "processed" ? (
                    <div className={"payoutRef"}>{"Success"}</div>//payout is success
                  ) : (
                    <div className={"payoutRef"}>{"NA"}</div>//payout is pending
                  )}
                </div>
                <div className={"frameGroup"}>
                  <div className={"payoutRefParent4"}>
                    <div className={"payoutRef"}>Debited At</div>
                    <div className={"payoutRef"}>:</div>
                  </div>
                  <div className={"payoutRef"}>
                    {payoutData["0"]?.dr_ledger_passed_on_timestamp || "NA"}
                  </div>
                </div>
                {payoutData["0"].status == "failed" && <div className={"frameGroup"}>
                  <div className={"payoutRefParent5"}>
                    <div className={"payoutRef"}>Credited At</div>
                    <div className={"payoutRef"}>:</div>
                  </div>
                 {payoutData["0"].status == "failed"&&<div className={"payoutRef"}>
                    {payoutData["0"]?.cr_ledger_passed_on_timestamp || "Processing"}
                  </div> }
                </div> }
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default EasypayoutsStatusScreen;
